<template>
  <BasePage
    :bannerUrl="banner"
    subTilte="新闻中心"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
  >
    <NewsModular />
    <PolicyModular />
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
import NewsModular from "./components/newsModular/newsModular";
import PolicyModular from "./components/PolicyModular/PolicyModular";

export default {
  components: { BasePage, NewsModular, PolicyModular },
  setup() {
    const data = reactive({
      ...imgData.NewsCenterImg,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

