<template>
  <BaseModular
    subTitle="新闻中心"
    title="公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的互联网产品"
  >
    <div class="newsModular_wrap">
      <div
        class="newsModular_left"
        style="cursor: pointer"
        v-for="item in leftData"
        :key="item.id"
        @click="setHtml(item)"
      >
        <div class="newsModular_left_img_wrap">
          <div class="newsModular_left_img" style="width: 100%">
            <!-- <img :src="leftData.thumbnailUrl" /> -->
            <img
              style="width: 100%; min-height: 360px"
              :src="
                item.thumbnailUrlList
                  ? item.thumbnailUrlList.length > 0
                    ? getUrl(item.thumbnailUrlList[0].name)
                    : NewsCenterImg.NewsCenterLeft
                  : NewsCenterImg.NewsCenterLeft
              "
            />

            <!-- <img :src="NewsCenterImg.NewsCenterLeft" /> -->
            <!-- <div class="newsModular_left_img_title_wrap">
              <div class="newsModular_left_img_title">
                {{ item.title }}
              </div>
            </div> -->

            <!-- <div class="newsModular_left_img_content">
              <div class="newsModular_left_img_content_subTitle">
                {{ item.title }}
              </div>

              <div class="newsModular_left_img_content_content">
                {{ item.description }}
              </div>
            </div> -->
          </div>
        </div>
        <div class="newsModular_left_content">
          <div class="newsModular_left_content_subTitle">
            {{ item.title }}
          </div>

          <!-- <div class="newsModular_left_content_content">
            {{ item.description }}
          </div> -->
        </div>
      </div>

      <div class="newsModular_right">
        <div class="newsModular_right_top">
          <div
            class="newsModular_right_item"
            v-for="item in topData"
            :key="item"
            style="cursor: pointer"
            @click="setHtml(item)"
          >
            <div class="newsModular_right_item_img_wrap">
              <img
                :src="
                  item.thumbnailUrlList
                    ? item.thumbnailUrlList.length > 0
                      ? getUrl(item.thumbnailUrlList[0].name)
                      : NewsCenterImg.NewsCenterRight.top.item1
                    : NewsCenterImg.NewsCenterRight.top.item1
                "
              />

              <!-- item.thumbnailUrlList[0].link -->
              <!-- getUrl(item.thumbnailUrlList[0].name) -->
              <!-- <p>{{ item.title }}</p> -->
            </div>

            <div class="newsModular_right_item_content">
              {{ item.title }}
            </div>
          </div>
        </div>

        <div class="newsModular_right_bottom">
          <div
            class="newsModular_right_item"
            style="cursor: pointer"
            v-for="item in bottomData"
            :key="item"
            @click="setHtml(item)"
          >
            <div class="newsModular_right_item_img_wrap">
              <img
                :src="
                  item.thumbnailUrlList
                    ? item.thumbnailUrlList.length > 0
                      ? getUrl(item.thumbnailUrlList[0].name)
                      : NewsCenterImg.NewsCenterRight.top.item1
                    : NewsCenterImg.NewsCenterRight.top.item1
                "
              />

              <!-- <p>{{ item.title }}</p> -->
            </div>

            <div class="newsModular_right_item_content">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-button class="newsModular_btn" @click="toNewsPage">了解更多</el-button>
  </BaseModular>
</template>


<script>
import imgData from "@/data";
import BaseModular from "@/components/BaseModular/BaseModular";
import { reactive, computed } from "vue";
import { mainStore, stateStore, NewsTableStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
export default {
  components: { BaseModular },
  setup() {
    const storeOfstate = stateStore();
    const storeOfNewsTable = NewsTableStore();
    const storeOfmain = mainStore();
    const router = useRouter();
    const setHtml = (item) => {
      storeOfstate.setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id, type: "新闻详情" },
        }).href,
        "_blank"
      );
    };
    const toNewsPage = () => {
      storeOfmain.setData("news_ActiveType", "news");
      router.push({
        path: "/ListOfNews",
      });
    };
    const leftData = computed(() => storeToRefs(storeOfmain).newsLeftData);
    const topData = computed(() => storeToRefs(storeOfmain).newsTopData);
    const bottomData = computed(() => storeToRefs(storeOfmain).newsBottomData);
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const data = reactive({
      leftData,
      bottomData,
      topData,
      ...imgData,
      // newsData,
    });

    return {
      ...data,
      toNewsPage,
      getUrl,
      setHtml,
    };
  },
};
</script>

<style  scoped>
@import url("./newsModular.css");
</style>