<template>
  <BaseModular
    title="公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的互联网产品"
    subTitle="政策资讯"
  >
    <div class="Policy_wrap">
      <div class="Policy">
        <div class="Policy_top">
          <div
            class="Policy_top_right"
            @click="setData('currentPage', 'pre')"
            style="cursor: pointer"
            :style="showLeft ? '' : 'visibility: hidden'"
          >
            <img
              :src="modularImg.Left"
              style="width: 72px; height: 72px; margin-right: 40px"
            />
          </div>
          <div
            class="Policy_top_item"
            v-for="(item, index) in thisPolicysData"
            :key="index"
            style="cursor: pointer"
            @click="setHtml(item)"
          >
            <img
              style="width: 102px; height: 102px"
              :src="
                item.thumbnailUrlList
                  ? item.thumbnailUrlList.length > 0
                    ? getUrl(item.thumbnailUrlList[0].name)
                    : topImgData[0]
                  : topImgData[0]
              "
            />
          </div>
          <div
            class="Policy_top_right"
            @click="setData('currentPage', 'next')"
            style="cursor: pointer"
            :style="showRight ? '' : 'visibility: hidden'"
          >
            <img :src="policyRight" />
          </div>
        </div>

        <div class="Policy_content">
          <div
            class="Policy_content_item"
            v-for="(item, index) in PolicyContentDemo"
            :key="index"
            @click="setHtml(item)"
          >
            <div class="Policy_content_item_title">
              {{ item.categoryName }}
            </div>
            <div class="Policy_content_item_line"></div>

            <div class="Policy_content_item_content" style="cursor: pointer">
              {{ item.description }}
            </div>
          </div>
        </div>

        <el-button class="Policy_btn" @click="toNewsPage">了解更多</el-button>
      </div>
    </div>
  </BaseModular>
</template>

<script>
import imgData from "@/data";
import { reactive, computed, onBeforeMount } from "vue";
import BaseModular from "@/components/BaseModular/BaseModular.vue";
import {
  mainStore,
  stateStore,
  policyModularStore,
  NewsTableStore,
} from "@/store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import _ from "lodash";

export default {
  components: { BaseModular },
  setup() {
    const router = useRouter();
    const storeOfmain = mainStore();
    const storeOfstate = stateStore();
    const storeOfthis = policyModularStore();
    const storeOfNewsTable = NewsTableStore();
    const thisPolicysData = computed(() => {
      return storeToRefs(storeOfthis).loadData;
    }); //PolicyInfo
    const currentPage = computed(() => storeToRefs(storeOfthis).currentPage);
    const setData = (type, value) => {
      storeOfthis.setData(type, value);
      storeOfthis.reloadData();
    };
    const toNewsPage = () => {
      storeOfmain.setData("news_ActiveType", "policy");
      // storeOfNewsTable.setData("activeType", "policy");
      router.push({
        path: "/ListOfNews",
      });
    };
    const setHtml = (item) => {
      storeOfstate.setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id, type: "政策资讯详情" },
        }).href,
        "_blank"
      );
    };
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const topImgData = Object.values(imgData.NewsCenterImg.policy);
    const PolicyContentDemo = computed(
      () => storeToRefs(storeOfmain).AllPolicyData
    );
    console.log(PolicyContentDemo, "PolicyContentDemo");
    const PolicyContent = [
      {
        title: "供应商审核规范",
        content:
          "致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新，致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新致于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新",
      },
      {
        title: "政策法规",
        content:
          "致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新，致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新致于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新",
      },
      {
        title: "采集新闻",
        content:
          "致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新，致力于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新致于创新科技,提供消费者个人电脑的更佳体验,也专为企业应用推出全方位的服务器产品,提供优化的商业解决方案,持续推动产业革新",
      },
    ];
    const showRight = computed(() => storeToRefs(storeOfthis).showRight);
    const showLeft = computed(() => storeToRefs(storeOfthis).showLeft);
    const data = reactive({
      topImgData,
      policyRight: imgData.NewsCenterImg.policyRight,
      PolicyContent,
      thisPolicysData,
      PolicyContentDemo,
      currentPage,
      showLeft,
      showRight,
    });

    return {
      ...data,
      toNewsPage,
      setHtml,
      getUrl,
      setData,
      ...imgData,
    };
  },
};
</script>

<style scoped>
@import url("./PolicyModular.css");
</style>

